import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { enquireScreen } from "enquire-js";
// import applySelect from "../../../assets/imgs/applySelect@2x.png";
// import delCheck from "../../../assets/imgs/delCheck.png";
import delCheck from "../../../assets/imgs/组7254@2x.png";
import "./index.less";
import "./index-Pc.less";

const lenLimit = "Número de @len dígitos";
const rangeLimit = "Número de @min-@max dígitos";
const phoneLimit = "Número de @num dígitos, que comienzan con @num";

export class DeleteUser extends React.PureComponent {
  state = {
    hasSubmit: false,
    textInfo: null,
    textFocus: false,
    inputFocus: false,
    textPhone: null,
    message: null,
    hasCommit: false,
    showModal: false,
    idCardType: null,
    idCard: null,
    idCardTypeDesc: null,
    idCardError: null,
    idCardFocus: false,
    agreeCheck: true,
    isMobile: false,
    showError: false,
    errorMsg: "ssss",
  };
  componentDidMount() {
    const stateTempJSON = localStorage.getItem("stateTemp");
    if (stateTempJSON) {
      this.setState({ ...JSON.parse(stateTempJSON) });
      setTimeout(() => {
        localStorage.removeItem("stateTemp");
      }, 500);
    }
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }
  initIdCardRule = (idCardType) => {
    console.log('initIdCardRule',idCardType ===1)
    const idCard = {
      len: idCardType && idCardType === 1 ? 8 : null,
      maxLen: idCardType && idCardType === 1 ? 8 : null,
      min:
        idCardType && idCardType === 2
          ? 9
          : idCardType && idCardType === 3
          ? 8
          : null,
      max: idCardType && (idCardType === 2 || idCardType === 3) ? 10 : 8,
    };
    return idCard;
  };
  handleFocusText = (flag) => {
    this.setState({ textFocus: flag });
  };
  buscarChangeText = (e) => {
    const { value } = e.target;
    this.setState({ textInfo: value });
  };
  buscarChangePhone = (e) => {
    console.log("");
    const { value } = e.target;
    const textPhone = value.replace(/[^0-9-]/g, "");
    // this.props.form.setFieldsValue({phone: textPhone})
    let errorMsg = null;
    const phonePattern = /^9\d{8}$/;
    errorMsg = !phonePattern.test(textPhone)
      ? phoneLimit.replace(/@num/g, 9)
      : null;

    this.setState({ textPhone, message: errorMsg });
  };
  buscarChangeIdCard = (e, idCardType) => {
    const { value } = e.target;
    const { len, min, max } = idCardType ? this.initIdCardRule(idCardType) : {};

    const idCard = value.replace(/[^0-9-]/g, "");
    console.log("value", value, idCard);
    // this.props.form.setFieldsValue({idCard})
    let errorMsg = null;
    if (idCardType) {
      let vLen = String(idCard || "").trim().length;
      // const {len, min, max} = this.initIdCardRule(idCardType)
      if (len && vLen !== len) {
        errorMsg = lenLimit.replace(/@len/g, len + "");
      }
      if (min && max) {
        if (min > vLen || max < vLen) {
          errorMsg = rangeLimit
            .replace(/@max/g, max + "")
            .replace(/@min/g, min + "");
        }
      } else {
        if (min && min > vLen) {
          errorMsg = lenLimit + " >= " + min;
        }
        if (max && max < vLen) {
          errorMsg = lenLimit + " <= " + max;
        }
      }

      console.log("checkValue", idCard, errorMsg);
      this.setState({ idCardError: errorMsg });
    }
    this.setState({ idCard });
  };
  handleSumit = async () => {
    const {
      textPhone,
      textInfo,
      idCard,
      message,
      idCardError,
      agreeCheck,
      idCardType,
      hasCommit,
    } = this.state;
    if (hasCommit || message || idCardError || !agreeCheck) {
      return;
    }
    const idCardTypeCheck = idCardType ? idCard && !idCardError : true;
    if (textPhone && idCardTypeCheck) {
      this.setState({ showError: true, errorMsg: null });
      let payload = {
        idCardType,
        idCard,
        reason: textInfo,
        phone: textPhone,
        appId: 11,
      };
      const res = await this.getData(payload);
      console.log("res", res);
      if (res && res.ret === 0) {
        this.setState({});
        this.setState({ hasCommit: false, showError: false, hasSubmit: true });
      } else if (res && res.ret !== 0) {
        this.setState({ errorMsg: res.msg });
        // notification.error({
        //   message: res.msg,
        //   duration: 3,
        // })
      }
    }
  };
  handleOK = () => {
    this.setState({ showError: false, errorMsg: null });
  };
  checkStatus = (response) => {
    const codeMessage = {
      200: "服务器成功返回请求的数据。",
      201: "新建或修改数据成功。",
      202: "一个请求已经进入后台排队（异步任务）。",
      204: "删除数据成功。",
      400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
      401: "用户没有权限（令牌、用户名、密码错误）。",
      403: "用户得到授权，但是访问是被禁止的。",
      404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
      406: "请求的格式不可得。",
      410: "请求的资源被永久删除，且不会再得到的。",
      422: "当创建一个对象时，发生一个验证错误。",
      500: "服务器发生错误，请检查服务器。",
      502: "网关错误。",
      503: "服务不可用，服务器暂时过载或维护。",
      504: "网关超时。",
    };
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const errortext = codeMessage[response.status] || response.statusText;
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  };
  getData = (payload) => {
    const res = fetch(window.location.origin + "/EliminarUsuario", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(this.checkStatus)
      .then((res) => {
        return res.json();
      })
      .catch((e, res) => {
        const status = e.name;
        let ret = status;
        let msg = "Falla del Sistema";

        if (status === 401) {
          msg = "Suspendido";
          return { ret, msg };
        }

        // environment should not be used
        if (status === 403) {
          msg = "Acceso Denegado";
        }
        if (status <= 504 && status >= 500) {
          msg = "Error de Sistema";
        }
        if (status >= 404 && status < 422) {
          msg = "No encontrado";
        }
        return { ret, msg };
      });
    return res;
  };
  buscarClick = (flag) => {
    this.setState({ showModal: !!flag });
  };
  handleSelectChange = (value, desc) => {
    console.log("idCardType value", value);
    if (value === -1) {
      this.setState({
        showModal: false,
        idCardType: null,
        idCardTypeDesc: "",
        idCardError: null,
        idCard: "",
      });
      return;
    }
    const { idCardType } = this.state; // 上次的值

    // ! idCardType 的值和上次的选择不一致,才清空 idCard
    if (idCardType !== value) {
      this.setState({
        idCardError: false,
        idCard: "",
      });
    }
    const { len, min, max } = this.initIdCardRule(value);
    let message = null;

    if (len) {
      message = lenLimit.replace(/@len/g, len + "");
    }
    if (min && max) {
      message = rangeLimit
        .replace(/@max/g, max + "")
        .replace(/@min/g, min + "");
    }

    this.setState({
      showModal: false,
      idCardType: value,
      idCardTypeDesc: desc,
      idCardError: message,
    });
  };
  buscarCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  buscarAgreeCheck = () => {
    let agreeCheck = this.state.agreeCheck;
    agreeCheck = !agreeCheck;
    this.setState({
      agreeCheck,
    });
  };
  render() {
    // const {hasSubmit,showModal,agreeCheck, isMobile, idCardType, idCardTypeDesc, idCardError, textPhone,inputFocus,idCardFocus, textFocus, message} = this.state
    const {
      hasSubmit,
      showModal,
      showError,
      errorMsg,
      agreeCheck,
      isMobile,
      idCardType,
      idCardTypeDesc,
      idCardError,
      textPhone,
      inputFocus,
      idCardFocus,
      textFocus,
      message,
      idCard,
    } = this.state;

    // const desc1Split = 'Asegúrese de no tener facturas pendientes en su cuenta. ; Al eliminar la cuenta, toda su información personal asociada será borrada permanentemente y perderá el acceso a ella.'.split(';')

    const { min, max } = idCardType ? this.initIdCardRule(idCardType) : {};
    const agsTitle = "Solicitud de eliminación de cuenta";
    const agsTop = "Solicitud";
    const agsAreaCode = "+51";
    const agsPhone = "9********(CredEke)";
    const asgType = "Tipo de certificado(Enviado en CredEke)";
    const asgCard = "Número de ID(Enviado en CredEke)";
    const asgMsg = "Motivo para eliminar esta cuenta";
    const asgCancel = "Cancelar";
    // const asgTips1 = 'Advertencia'
    // const asgTips2 = 'Proceso de Eliminación'
    const asgAgree = "Aceptar los";
    const Privacidad = "<Privacidad>";
    const Servicio = " <Términos>";
    const agreeAnd = " y ";
    // const submit = "Confirmar y Recibir";
    const submit = "Solicitud";
    const okText = "CONFIRMAR";
    const loadingText = "Cargando...";
    const result = "Su solicitud de eliminación ha sido enviada con éxito.";

    
    
    const renderDict = () => {
      let dicts = [
        { value: 1, label: "DNI" },
        { value: 2, label: "Pasaporte" },
        { value: 3, label: "CE" },
      ];

      return (
        <div
          className={["selectModal", isMobile && "selectModal-mob"].join(" ")}
        >
          <div className={["modalContent"].join(" ")}>
            <div className={"listHead"}>
              <div>{asgType}</div>
            </div>
            <div className={["list"].join(" ")}>
              {dicts.map((i, index) => (
                <div
                  className={[
                    "listItem",
                    idCardType === i.value && "listItemOn",
                  ].join(" ")}
                  key={i.value}
                  checked={idCardType === i.value}
                  onClick={() => this.handleSelectChange(i.value, i.label)}
                >
                  {i.label}
                </div>
              ))}
              <div
                className={"listItem"}
                key={-1}
                onClick={() => this.handleSelectChange(-1, null)}
              >
                {asgCancel}
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div
        className={[
          "deleteWrapper" + (isMobile ? "-mob" : ""),
          (idCardFocus || textFocus) &&
            isMobile &&
            "edit" + (isMobile ? "-mob" : ""),
          hasSubmit && "deleteWrapperColor" + (isMobile ? "-mob" : ""),
        ].join(" ")}
      >
        <div className={"tabNav"}>
          <div className={"title"}> {agsTitle}</div>
        </div>
        {!hasSubmit ? (
          <>
            <div className={"content"}>
              {/* {
                  desc1Split.map((desc1, idx) => {
                    return  <div className={'desc'}>
                      <div>{`${idx+1}.`}</div>
                      <div>{desc1}</div>
                    </div>
                  })
                }
            <div className={'tip'}>{asgTips2}</div> */}

              <div className={"fbtInfo"}>
                <div className={"tip"}>{agsTop}</div>
                <div className="fbt-info-container">
                  <div
                    className={[
                      "fbtPhone",
                      "fbtTop",
                      inputFocus && "fbtContentOn",
                    ].join(" ")}
                  >
                    <div className={"fpInfo"}>
                      <div className={"fpLabel"}>{agsAreaCode}</div>
                      <input
                        className={"fpInput"}
                        onChange={(value) => this.buscarChangePhone(value)}
                        onKeyUp={(event) => this.buscarChangePhone(event)}
                        placeholder={agsPhone}
                        onFocus={() => {
                          this.setState({ inputFocus: true });
                        }}
                        onBlur={() => {
                          this.setState({ inputFocus: false });
                        }}
                        type={"tel"}
                        maxLength={9}
                      />
                    </div>
                    {!!message && (
                      <div className={["showTips"].join(" ")}>
                        <span>{message}</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={["itemSelect"].join(" ")}
                    onClick={() => this.buscarClick("idCard.idCardType", true)}
                  >
                    <input
                      className={"iSelect"}
                      placeholder={asgType}
                      readOnly={true}
                      value={idCardTypeDesc}
                    />
                    {/* <img className={"itemImg"} alt="se" src={applySelect} /> */}
                  </div>
                  <div
                    className={[
                      "fbtPhone",
                      idCardFocus && "fbtContentOn",
                      !idCardType && "idCard",
                    ].join(" ")}
                  >
                    <div className={"fpInfo"}>
                      <input
                        className={["fpInput", "fpIdCard"].join(" ")}
                        onKeyUp={(event) =>
                          this.buscarChangeIdCard(event, idCardType)
                        }
                        placeholder={asgCard}
                        onFocus={() => {
                          this.setState({ idCardFocus: true });
                        }}
                        onBlur={() => {
                          this.setState({ idCardFocus: false });
                        }}
                        disabled={!idCardType}
                        type={"tel"}
                        value={idCard}
                        maxLength={max}
                        minLength={min}
                        onChange={(currentValue) => {
                          this.buscarChangeIdCard(currentValue, idCardType);
                        }}
                      />
                    </div>
                    {!!idCardError && (
                      <div className={["showTips"].join(" ")}>
                        <span>{idCardError}</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={[
                      "fbtContent",
                      textFocus ? "fbtContentOn" : null,
                    ].join(" ")}
                  >
                    <textarea
                      maxLength={100}
                      onChange={(value) => this.buscarChangeText(value)}
                      className={[
                        "fpText",
                        textFocus ? "fbtContentOn" : null,
                      ].join(" ")}
                      onFocus={() => this.handleFocusText(true)}
                      onBlur={() => this.handleFocusText(false)}
                      placeholder={asgMsg}
                    />
                  </div>
                  <Router>
                  <div className={"agreeWrapper"}>
                    <div
                      className={["laCheck", agreeCheck && "hasCheck"].join(
                        " "
                      )}
                      onClick={this.buscarAgreeCheck}
                    >
                      {agreeCheck && (
                        <img className="laImg" src={delCheck} alt="s" />
                      )}
                    </div>
                    <div className={"laText"}>
                      <span className={"normolColor"} style={{ margin: "0 3px" }}>{asgAgree}</span>
                      <Link to="/acuerdoTerminos" target="_blank">
                        <span className={"doc"}>{Servicio}</span>
                      </Link>
                      <span
                        className={"normolColor"}
                        style={{ margin: "0 3px" }}
                      >
                        {agreeAnd}
                      </span>
                      <Link to="/acuerdoPolitica" target="_blank">
                        <span className={"doc"}>{Privacidad}</span>
                      </Link>
                    </div>
                  </div>
                </Router>
                </div>
                {/* agree checkbox */}
              </div>
              {!isMobile && (
                <div
                  className={[
                    "fbtAction",
                    textPhone && !idCardError && agreeCheck && !message
                      ? "fbtActionOn"
                      : null,
                  ].join(" ")}
                  onClick={() => this.handleSumit()}
                >
                  <div className={"fbtDo"}>{submit}</div>
                </div>
              )}
              {isMobile && (
                <div
                  className={[
                    "fbtActionMobile",
                    textPhone && !idCardError && agreeCheck && !message
                      ? "fbtActionOn"
                      : null,
                  ].join(" ")}
                  onClick={() => this.handleSumit()}
                >
                  <div className={"fbtDo"}>{submit}</div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={"fbtSuccess"}>
            <span>{result}</span>
          </div>
        )}
        {showModal && renderDict()}

        {showError && (
          <div className={["errorModal"].join(" ")}>
            <div
              className={["errorContent", !errorMsg && "loadingContent"].join(
                " "
              )}
            >
              {errorMsg ? (
                <div
                  className={[
                    "errorHeader",
                    isMobile && "errorHeader-mob",
                  ].join(" ")}
                >
                  ×
                </div>
              ) : (
                <div
                  className={["loader", isMobile && "loader-mob"].join(" ")}
                />
              )}
              <div
                className={["errorMsg", isMobile && "errorMsg-mob"].join(" ")}
              >
                {errorMsg || loadingText}
              </div>
              {errorMsg && (
                <div
                  onClick={() => this.handleOK()}
                  className={["btn", isMobile && "btn-mob"].join(" ")}
                >
                  {okText}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DeleteUser;
