import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import point from '../assets/imgs/point.png';
import bannerBg from '../assets/imgs/homeBg.webp';



 class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    fetch( window.location.origin + '/paquete',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  
  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl } = this.state;
     const subtitle = 'Préstamo'
     const titleDesc = 'simple y rápido,  le ayudará a resolver el problema'

     const desc = 'Solicita en cualquier momento y lugar'
     const desc1 = 'Solo 3 pasos'
    const desc2 = 'Solicitud - Evaluación - Desembolso'
    

     return (
      <Element id="home" component="section" className={(isMobile ? 'block-mobile ': 'block')}  onChange={navToShadow}>
        <div className={className + (isMobile ? '-mobile ': '')}>
          <div>
            <div className={`content`}>
                    <div>
                      {/* <img alt='title' src={contentTitle} className={isMobile ? 'title-mobile' : 'title'}/> */}
                      <div className={`subBox`}>
                        <div>
                          <div className={`subtitle`}>{subtitle}</div>
                          <div className={`titleDesc`}>{titleDesc}</div>
                        </div>
                        {isMobile ? <img src={bannerBg} className='bannerBg' alt='bannerBg'/> : null}
                      </div>
                      <div className={`desc`}>{desc}</div>
                      <div className='newDesc'>
                          <div className={`desc1`}>{desc1}</div>
                          <div className={`desc2`}>{desc2}</div>
                      </div>  
                    </div>
                    
              </div>
            <a target="_blank" rel="noopener noreferrer" href={appUrl} key="a" className={isMobile ? 'word-download-mobile' : `word-download`}>
              Descargar CredEke
              <img src={point} className={'point'} alt='point'/>
            </a>
          </div>
        </div>
      
      </Element>
    );
  }
}

export default Banner;
